


.heading{
    text-align: left;
    margin-left: 120px;
}
.heading4{
    text-align: left;
    font-weight: 500;
    font-size: 20px;
}

.Alltransaction-desktop20 table tbody tr td{
    border: 5px solid #ffffff;
    padding: 3px 8px;
    background: #f0f0f0;
    border-radius: 12px;
    font-weight: 500;
    color: #333;
}

.Validatedby {
    display: flex;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    font-weight: 500;
    flex-direction: row;
}

.Validatedby2 {
    display: flex;
    flex: 0 0 11%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.Validatedby2:nth-child(2) {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.Validatedby span:nth-child(1){
    padding-right: 5px;
    position: relative;
    font-weight: 600;
    line-height: 1.8;
}
.Validatedby span:nth-child(2) img{
    margin-right: 5px;
}
.Alltransaction-address{
    padding-left: 10px;
}

.blockminerlogo {
    display: flex;
    padding: 0;
    width: 15px;
    height: 16px;
    margin-left: 220px;
    margin-top: -17px;
    margin-bottom: 30px;
}

.mineraddress {
    display: flex;
    margin-top: -52px;
    margin-left: 250px;
    color: #1381bd;
}
.mineraddress1{
    display: none;
}

.block-tabs {
    text-align: left;
    margin-left: 150px;
    margin-top: 10px;
}
.block-tabs6 {
    text-align: left;
    padding-left: 19px;
    margin-top: 15px;
    margin-bottom: 15px;
}


.transaction-detail {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-top: 20px;
}

.Alltwoaddress{
    display: flex;
    flex-direction: row;
    display: flex;
    flex-direction: row;
    flex: 1;
    /* width: 100%; */
    align-items: center;
    justify-content: start;

}

.transaction-detail6 {
    display: flex;
    flex-direction: column;
    padding-left: 3%;
    margin-top: 0;
    padding-right: 3%;
}



.key {
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    margin-left: 90px; 
    margin-bottom: 10px;
    color: #333;
}

.key6 {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    color: #333;
    flex: 0 0 16%;
}
.blockminerlogo6{
    width: 18px;
}

.Alltransaction-address-logo6{
    width: 18px;
}

.Topaccounts-address-logo-mobile{
    width: 18px;   
}

.Topaccounts-arror-logo6{
    width: 18px;  
}

.Topaccounts-address1-logo-mobile6{
    width: 18px;  
}

.ValidatedbyCont{
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 8px;

}
.bitaXcmd{
    font-weight: normal;
    word-break: break-word;
}
.Alltransaction-desktop20{
    overflow-x: auto;
}
.Topaccounts-data-mobile8 {
    display: flex;
    
}

.text-value {
    text-align: left;
    margin-left: 400px;
    font-size: 18px;
    margin-top: -35px;
    margin-bottom: 10px;
    position: relative;
    top: -7px;
}



/* Styles for mobile view */
@media (max-width: 600px) {
    .block-tabs6 button {
        margin-left: 1%;
        padding: 6px 12px;
    }
    .heading{
        text-align: left;
        margin-left: 16px;
    }
    .Validatedby {
        text-align: left;
        margin-left:0;
        margin-bottom: 10px;
        flex-direction: column;
    }
    .Validatedby2 {
        align-items: center;
        justify-content: flex-start;
    
    }
    .ValidatedbyCont {
        padding-left: 0;
    }
    .blockminerlogo {
        display: flex;
        padding-left: 0;
        padding-right: 0;
        width: 15px;
        height: 16px;
        margin-left: 120px;
        margin-top: -26px;
    }
    


    .mineraddress {
        display: none;
      
    }
    .mineraddress1 {
        display: flex;
        text-align: left;
        margin-left: 150px;
        margin-top: -50px;
        color: #1381bd;
    }

    .block-tabs {
        text-align: left;
        margin-left: 0;
        margin-top: 20px;
    }

    .transaction-detail {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-top: 0;
    }

    .key {
        text-align: left;
        font-weight: 500;
        font-size: 18px;
        margin-left: 10px; 
        margin-top: 16px;
        margin-bottom: 10px;
    }

    

    .text-value {
        position: relative;
        left: 0;
        text-align: left;
        font-size: 18px;
        margin-top: 0;
        margin-left: 3%;
        margin-bottom: 3px;
    }

    .minerlogo {
        /* Add mobile styles here */
        text-align: left;
        width: 15px;
        height: 16px;
        margin-right: 5px;
    }

    .infologo {
        text-align: left;
        width: 40px;
        margin-right: 10px;
        margin-bottom: 5px;
    }
    .timestamp-ist {
        display: none;      
    }
}
@media (max-width: 500px) {
    .transaction-detail {
        display: flex;
        flex-direction: column;
        margin-left: 3%;
        margin-top: 0;
    }}
    
@media (max-width: 768px) {
    .Topaccounts-data-mobile8 {
        display: flex;
        
    }
    .blockminerlogo6 {
        width: 18px;
    }
.key6 {
    flex: 0 0 48%;
}
.transaction-detail6 {
    display: flex;
    flex-direction: column;
    margin-left:0;
    margin-top:0;
    padding: 0;
}
.block-tabs6 {
    text-align: left;
    padding-left: 0;
    margin-top: 10px;
}
.Validatedby span:nth-child(1){
    line-height: 1;
}

.text-value6{
    flex: 1;
    padding-left: 1px;
}

.Validatedby {
padding-left: 0;
flex-direction: column;
}
.Validatedby2 {
    align-items: center;
    justify-content: flex-start;
}
}

.infologo2{
    width: 18px;
    margin-right: 5px;
}

.key i{
margin-right: 1%;
}




.text-value6 {
    text-align: left;
    font-size: 18px;
    position: relative;
    word-break: break-all;
}

.infologo6 {
    text-align: left;
    width: 20px;
    margin-right: 5px;
}
.transaction-detail12{
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.minerlogo {
    text-align: left;
    width: 15px;
    height: 16px;
    margin-right: 5px;
}
.infologo {
    text-align: left;
    width: 35px;
    margin-right: 5px;
    margin-bottom: 5px;
}
.timestamp-ist{
    position: relative;
    text-align: right;
    margin-right: 550px; 
    font-size: 18px;
    margin-top: -35px;
    margin-bottom: 10px;
}

  .tab-button {
    border: 20px;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px;
    background-color: transparent; /* Remove background color */
    border: 5px; /* Remove border */
    outline: none; /* Remove outline */
    font-weight: 600;
    font-size: 16px;
    margin-left: -20px;

  }
  
  .tab-button.active {
    background-color: #ebf8ff; /* Background color when active */
    color: black;
    border: 5px;
    border-radius: 4px;
  }
  
  .tab-button:hover {
    color: #333; /* Change text color on hover */
  }

