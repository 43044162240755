


.headertransactionlogo23{
  width: 21px;
}

.headertransactionlogo{
  width: 20px;
}




.notification {
  background-color: #990000;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures items are centered vertically */
  padding: 0;
  flex-wrap: wrap; /* Allows items to wrap on small screens */
}

.notificationprice, .notificationgas {
  color: #ebf8ff;
  font-size: large;
  margin: 1rem;
  font-weight: 500;
}
a.nav-link{
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: center;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
      justify-content: center;
  }
}

.logo {
  margin-left: 0px;
}
a.logo {
   padding: 0 !important;
   margin: 0 !important;
}


.logo img{
  max-width: 100%;
}


.navbar-header1 {
  display: flex;
  align-items: center;
}

.navbar-header {
  display: flex;
  align-items: center;
}

.blockchainheaderlogo {
  width: 18px;
  height: 18px;

  
}

span.headertransactionlogo {
  display: block;
  width: 32px;
  height: 32px;
  padding: 3px 6px 3px;
}
span.headertransactionlogo img{
  width: 100%;

}

.mr-auto {
  margin-left: 240px;
}

.flogocss {
  width: 2rem;
}
.search-container{
  width: 59rem;
}
.searchbar-fullwidth {
  width: 59rem;
}
.FufiBlockchain{
  font-size: 26px;
  font-weight: 700;
  line-height: 20px;
  margin-right: 12px;
  color: rgb(164, 22, 22);
}
.header-text{
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin:1rem;
}
.header-text1{
  display: none;
}
@media (max-width: 750px) {
  .notification {
    justify-content: center; /* Centers items in a row on small screens */
  }

  .navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
        width: 100%;
  }

  .navbar-toggle {
    order: 1;
  }

  .logo {
    padding-right: 190px;
    order: 0;
    margin-left: 0px;
  }

  .mr-auto {
    margin-left: 0;
    margin-top: 0px;
    margin-left: 20px;
  }

  .navbar-collapse {
    flex-grow: 0;
    text-align: left;
    width: 100%;

  }

  .blockchainheaderlogo {
    width: 18px;
    height: 18px;
    margin-top: -5px;
  }

  .headertransactionlogo {
    width: 16px;
    height: 16px;
  }
  .header-text{
  display: none;
  }
  .header-text1{
    display: flex;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin:1rem;
  }
}


