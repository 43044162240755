.Allblock-detail-container {
  padding: 0;
  position: relative;
}
.pagination3 {
  display: flex;
  justify-content: right;
  margin-top: 0;
  float: right;
  position: absolute;
  right: 3%;
  top: 0;
}
.Allblock-heading {
  display: flex;
  margin-left: 4.5%;
  font-size: 28px;
  font-weight: 500;
  margin-top: 10px;
}
.Allblock-header7 .Allblock-card{
  padding: 4px 20px 0px !important;
  background: #fff;
  border-radius: 4px;
  color: #424242;
}

.Alltransaction-tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab-button {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: #990000;
  color: #ffffff;
}

.Allblock-header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 10px;
  color: rgb(75, 75, 75);
  background-color: #ededed;
  border-radius: 5px;
  margin-left: 4%;
  margin-right: 4%;
  gap: 10px;
  padding: 2px 9px;
  font-weight: 600;
}

.Allblock-cards-container {
  display: flex;
  flex-direction: column;
  /* Make sure the cards stack vertically */
  gap: 1px;
}

.Allblock-card {
  position: relative;
  margin-left: 4%;
  margin-right: 4%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 8px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
}

.Allblock-column {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.Allblocknumber-column {
  font-weight: bold;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 5px;
}

.Allblockaddresslogo {
  text-align: left;
  width: 15px;
  height: 16px;
  margin-right: 5px;
}

.Allblock-time {
  margin-top: 0px;
  position: absolute;
  bottom: 5px;
  left: 20px;
  font-weight: 600;
  font-size: 13px;
}

.Allblock-text-size,
.Allblocksize,
.Allblock-text-totaltransaction,
.Allblock-text-gasUsed,
.Allblock-text-miner {
  display: none;
}


.pagination {
  display: flex;
  align-items: right;
  justify-content: right;
}

.pagination3 {
  display: flex;
  align-items: right;
  justify-content: right;
}
.pagination-button, .pagination-button-next {
  cursor: pointer;
  padding: 10px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}

.pagination-page-first{
  margin-right: 0px;
  padding: 5px 20px;
  margin-top: 10px;
  border-radius: 4px;
  background-color: #cfcfcf;
  margin-bottom: 10px;
  cursor: pointer;
}
.pagination-page-number {
  padding: 10px;
  margin: 0 5px;
  font-size: 1.2em;
}


@media (max-width: 768px) {
  .Allblock-heading {
    margin-left: 20px;
    text-align: left;
  }

  .Alltransaction-tabs {
    justify-content: left;
  }

  .Allblock-header {
    display: none;
  }
  .Allblock-header7 {
    display: none;
  }








    .Allblock-heading {
      margin-left: 20px;
      text-align: left;
    }

    .Alltransaction-tabs {
      justify-content: left;
    }

    .Allblock-header {
      display: none;
    }
    .Allblock-header7 {
      display: none;
    }

    .Allblock-card {
      text-align: left;
      background-color: white;
      border-bottom: 1px solid #e0e0e0;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      border: 0;
      width: 100%;
      height: 220px;
      margin-left: 0px;
      border-radius: 8px;
      box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
      margin-top: 5px;
    }

    .Allblocknumber-column {
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    .Allblock-text-size {
      display: flex;
      margin-top: px;
      font-size: 20px;
      color: #414243;
    }

    .Allblocksize-column {
      font-size: 18px;
      margin-top: -30px;
      margin-left: 60px;
      color: #414243;
    }

    .Allblock-text-miner {
      display: flex;
      font-size: 18px;
      margin-left: 0px;
    }

    .Allblock-miner {
      display: flex;
      margin-left: 80px;
      margin-top: -32px;
    }

    .Allblock-text-totaltransaction {
      display: flex;
      font-size: 18px;
      margin-left: 0px;
    }

    .Allblock-totaltransaction {
      margin-left: 40px;
      margin-top: -30px;
    }

    .Allblock-text-gasUsed {
      display: flex;
      font-size: 18px;
    }

    .Allblock-gasUsed {
      margin-left: 80px;
      margin-top: -30px;
    }

    .Allblock-text-timestamp {
      display: flex;
      font-size: 18px;
    }

    .Allblock-time {
      margin-left: 300px;
      margin-top: -184px;
    }

    .Allblockaddresslogo {
      text-align: left;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      margin-top: 7px;
    }


    
.pagination3 {
  display: flex;
  align-items: right;
  justify-content: right;
  position: absolute;
  right: 0;
  top:0;
  float: right;
}
.pagination-button, .pagination-button-next {
  cursor: pointer;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}

.pagination-page-first{


  margin-right: 0px;
  margin-top: 5px;
  padding: 5px 20px;
  border-radius: 10px;
  background-color: #317fa9;
  margin-bottom: 10px;
  cursor:pointer
}
.pagination-page-number {
  padding: 10px;
  margin: 0 5px;
  font-size: 1.2em;
}

  }

 

