.addressheading {
    text-align: left;
    margin-left: 140px;
    font-size: 28px;
    font-weight: bold;
    color: #433a3a;
}

.alladdresslogo {
    align-items: flex-start;
    width: 45px;
    margin-right: 5px;
}

.alladdress {
    text-align: left;
    margin-left: 140px;
    font-weight: 500;
    font-size: 18px;
}

.alladdress1 {
    display: none;
}

.address-tabs {
    text-align: left;
    margin-left: 2.8%;
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.address-button {
    border: none;
    /* Remove border */
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px;
    background-color: transparent;
    /* Remove background color */
    outline: none;
    /* Remove outline */
    font-weight: 600;
    font-size: 16px;
    position: relative;
    /* Necessary for the pseudo-element */
    overflow: hidden;
    /* Hide the sliding effect overflow */
    transition: color 0.3s ease;
    /* Smooth color transition */
    display: flex;
    align-items: center;
    justify-content: center;
}

.address-button::after {
    content: '';
    position: absolute;
    top: 0;
    right: -100%;
    /* Start from outside the button */
    width: 100%;
    height: 100%;
    background-color: #d97474;
    /* Background color for the slider */
    transition: right 0.3s ease;
    /* Smooth sliding transition */
    z-index: -1;
    /* Ensure it is behind the text */
}

.address-button.active::after {
    right: 0;
    /* Slide in from the right */
}

.address-button.active {
    color: black;
    /* Change text color when active */
    border-radius: 8px;
}

.address-button:hover {
    color: #9a0606;
    /* Change text color on hover */
}

.Token-transfers {
    height: 300px;
    text-align: center;
    font-size: larger;
    font-weight: 600;
    text-transform: capitalize;
}

.Tokens {
    text-align: center;
    font-size: large;
    height: 300px;
    font-weight: bold;
}

.Internal-txns {
    height: 300px;
    /* Adjust margin for better spacing */
    text-align: center;
    font-weight: bold;
    font-size: large;
}

.address-item {
    display: flex;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 1px;
    margin-bottom: 6px;
}

.transaction-type {
    color: #d97474;
    font-weight: bold;
}

.topaddress-item {
    display: flex;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1px;
    margin-bottom: 6px;
  }
  
  .Topaccounts-index {
    gap: 1rem
  }
  .topaccounts-address{
    color: #6a2121;
    font-weight: bold;
  }

  .topaccounts-heading {
    font-size: 30px;
    font-weight: 500;
    padding: 0 3%;
    text-align: left;
}

.popup-box {
    position: absolute;
    top: 50px; /* Adjust this according to your layout */
    left: 50%;
    transform: translateX(-50%);
    background-color: #691919;
    color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    z-index: 1000;
  }
  
  .popup-box p {
    margin: 10px 0;
  }
  
  .popup-box button {
    margin-top: 10px;
  }
  
@media only screen and (max-width: 768px) {
    .addressheading {
        margin-left: 15px;
        font-size: 24px;
        /* Adjust font size for mobile */
    }

    .alladdresslogo {
        display: flex;
        text-align: left;
        width: 40px;
        margin-top: -2px;
        margin-left: 0;
    }

    .alladdress {
        display: none;
    }

    .alladdress1 {
        display: flex;
        text-align: left;
        margin-left: 5%;
        margin-top: 0;
        font-weight: 500;
        font-size: 18px;
    }

    .address-tabs {
        margin-left: 0;
        /* Reduce margin for mobile */
        flex-wrap: wrap;
        /* Allow wrapping in mobile view */
    }

    .address-button {
        margin: 5px 0;
        /* Adjust margins for mobile */
        padding: 8px 10px;
        /* Adjust padding for mobile */
        font-size: 14px;
        /* Adjust font size for mobile */
    }

    .address-arrow1 {
        text-align: center;
        margin: 5px 0;
        margin-top: 14px;
        margin-right: 120px;
        transform: rotate(0deg);
    }

    .alladdress-twoaddress {
        margin-top: 110px;
        margin-left: -230px;
    }

    .alladdress-address1 {
        margin-left: 200px;
        margin-top: -50px;
    }

    .address-item {
        text-align: left;
        grid-template-columns: 1fr;
    }

    .address-item .transaction-status,
    .address-item .transaction-hash {
        grid-column: span 2;
        /* Ensures these items take up full row in mobile view */
    }
    .topaddress-item {
        text-align: left;
        grid-template-columns: 1fr;
    }

}