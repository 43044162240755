/* Parent container for flexbox layout */
.chart-container {
  display: flex;
  justify-content: space-between;

  width: 100%;
  flex-wrap: wrap;
}

.cardchart {
  background-color: rgb(255, 255, 255);
  border-radius: 1px;
  padding: 20px;
  flex: 1;
}

.chart-container .cardchart:nth-child(1) {
  margin: 1% 3% 0;
  border: 1px solid #ece8e8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.chart-container .cardchart:nth-child(2) {
  display: flex;
    flex-direction: column;
    padding: 1% 8%;
    justify-content: space-between;
}

/* 
.chartdata {
  background-color: rgba(255, 255, 255, 0.596);
  padding-right: 90px;
  width: 30%;
  height: 240px;
  padding-top: 20px;
  color: rgba(163, 37, 33, 0.596);
} */

.headingd {
  font-size: 20px;
  margin-bottom: 10px; /* Add margin-bottom for spacing */
  font-weight: 600;

}

.DailyTxn{
  display: flex;

  font-size: 20px;
  margin-bottom: 10px; /* Add margin-bottom for spacing */
  font-weight: 500;
  color: #718096;

}

.grid-chart {
  padding: 15px 10px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border: 1px solid #ece8e8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.grid-chart:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.chart-title span:nth-child(1){
  font-size: 26px;
  font-weight: 600;
  color: #ea3138;
}

.chart-title .subtitle{
  font-size: 26px;
  font-weight: 600;
  color: #333;
}


.subtitle{
  color: #718096;

}

.chartlogo {
  width: 25px;
  height: 25px;
  float: left; /* Align image to the left */
}

@media (max-width: 600px) {
  .cardchart {
    padding: 0;
  }

  .headingd {
    font-size: 22px;
  
  }
  .DailyTxn {
    font-size: 20px;
    margin-bottom: 10px; /* Add margin-bottom for spacing */
    font-weight: 500;
  }

  .chartdata {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .grid-chart {
    transition: transform 0.3s ease;
    cursor: pointer;
  }

  .grid-chart:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
}
