.login-details {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #f0f0f0; /* Optional background color */
}

.login-card {
    background-color: white;
    padding: 20px;
    margin-top: 0;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    margin-bottom: 5%;
    margin-top: 4%;
    text-align: center;
}





.Welcome{
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 8px;
    font-weight: 700;
}
.loginform {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.form-group {
    width: 100%;

    margin-right: 100px;
    margin-bottom: 15px;
}

.form-control1 {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc; /* Add border */
    border-radius: 4px;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    font-size: 16px; /* Adjust font size if needed */
}

.form-control1::placeholder {
    color: #888; /* Placeholder color */
}

.btn {
    width: 100%;
    padding: 10px;
    background-color:#990000;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}



.btn:hover {
    background-color: #0f0808;
}

.Forget-password{
    margin-right: 170px;
    margin-bottom: 20px;
    color: #990000;
}
.dont-account{
    text-align: left;
    margin-right: 100px;
    margin-top: 10px;
    font-size: 14px;
}
.Sign-up{
    margin-left: 60px;
    margin-top: -22px;
    margin-bottom: 20px;
    color: #990000;
    cursor: pointer;
}
.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    width: 100%;
    text-align: center;
}

.description-box {
    height: 140px; /* Adjust the height as needed */
    resize: vertical; /* Allow users to resize the textarea vertically */
}

@media (max-width: 500) {
    .login-card {
        background-color: white;
        padding: 50px;
        margin-top: 0;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        max-width: 400px;
        width: 100%;
        text-align: center;
    }
    
    .login-details {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0f0f0;
        height: auto;
    }
  
    }




    @media (max-width: 768px) {

        .login-card {
            background-color: white;
            padding: 50px;
            margin-top: 0;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            max-width: 400px;
            width: 100%;
            text-align: center;
        }
        .login-details {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f0f0f0;
            height: auto;
        }
        }
        
        
