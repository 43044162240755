.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
  padding: 20px 3%;
}

.grid-item {
  background-color: #ffffff;
  padding: 10px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ece8e8;
}


.grid-item:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.grid-title {
  font-size: 14px;
  color: #760a0a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: inherit;
  gap: 5px;
}

.grid-title .grid-titleInx:nth-child(2){
  display: flex;
  flex-direction: column;
    color: #333;
    font-weight: 600;
}

.grid-title img{
  width: 28px;
}


.grid-title-text {
  margin-top: 10px;
 
}

.grid-data {
  font-size: 20px;
  margin-top: -10px;
  color: #870d0d;
  font-weight: 500; /* Light bold */
  margin: 0 auto;
}

/* Media query for tablets and smaller screens */
@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
  }
}

@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
    padding: 25px;
  }

  .grid-item {
    padding-top: 5px;
  }

  .grid-title {
    font-size: 14px;
    color: #718096;
  }

  .grid-data {
    font-size: 14px;
    margin-top: -5px;
  }

  /* Make the last grid item full width in mobile view */
  .grid-item:last-child {
    grid-column: span 2;
  }
}
