.wallet-container {
    background-color: #e4e4e4;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: aliceblue;
    padding-top: 8%;
    padding-bottom: 10%;
}

.wallet-cardContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    padding-left: 5%;
    padding-right: 5%;
}

.Wallet-heading {
    font-size: 28px;
    color: #333;
    margin-top: -40px;
    text-align: center;
    
}

.wallet-card {
    padding: 20px;
    margin-top: 40px;
    border-radius: 10px;
    flex: 1 0 45%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 158px;
    background-color: #fff;
    color: #000;
    text-align: center;
    cursor: pointer;
    align-items: center;
}
.createwalletlogo{
    display: flex;
    width: 100px;
    position: relative;
    padding: 16px;
}

.Wallet-heading1 p{
    margin-bottom: 0;
    font-size: 25px;
    color: #990000;
}

.button-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.private-key{
    text-align: left;
    font-size: 30px;
    margin-top: -100px;
    margin-left: 150px;
    
}
.Wallet-heading1{
    text-align: left;
    font-size: 18px;
    margin-left: 0;
    text-align: justify;
}

.wallet-button {
    background-color: #ff9800; /* Orange background */
    color: white; /* White text */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.wallet-button:hover {
    background-color: #e68900; /* Darker orange on hover */
}

.wallet-message {
    font-size: 18px;
    margin-top: 0;
}
@media (max-width: 600px) {
    .wallet-card {
        flex-direction: row;
        width: 100%;
        flex: 1 0 100%;
        align-items: center;
        
    }
    .Wallet-heading {
        font-size: 26px;
        color: #333;
        margin-top: 10px;
        text-align: center;
        line-height: 1;
    }


}

@media (max-width: 500px) {
    .wallet-card {
        flex-direction: row;
        width: 100%;
        flex: 1 0 100%;
        align-items: center;
    }

    .wallet-cardContainer {
        gap: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .createwalletlogo {
        width: 70px;
        margin: 0 auto;
        position: relative;
        left: -13px;
    }

    .Wallet-heading1 p {
        text-align: left;
        margin-bottom: 0;
        font-size: 25px;
    }
}

@media (max-width: 768px) {

    .wallet-container {
        background-color: #e4e4e4;
        width: 100%;
        height: 720px;
        padding: 20px; /* Add padding for spacing */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: aliceblue; /* Set default text color */
    }

    .wallet-card {
        width: 100%; /* Adjust width for smaller screens */
        margin-right: 0px;
        margin-top: 30px;
        position: relative;
        align-items: center;
    }

    .createwalletlogo {
        width: 70px;
        margin: 0 auto;
    }

    .private-key{
        margin-left: 70px;
        margin-top: -80px;

    }
    .Wallet-heading1 {
        margin-left:0;
        margin-bottom: 20px;
        text-align: justify;
    }
    .Wallet-heading1 p{
        text-align: left;
        margin-bottom: 0;
    }
}

