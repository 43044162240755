.block-logo {
  width: 25px;
  position: absolute;
}
.tableBxd{
  position: relative;
}


.transacHash{
  display: block;
  position: relative;
  left: 5px;
  font-weight: 600;
}


.innErBoxContent{
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #ece8e8;
  padding: 2% 0;
}

.innErBoxContent .innErBoxCon-Box:nth-child(1) {
  flex: 0 0 15%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innErBoxCon-Box{
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  font-size: 18px;
}


.block-timestamp {

  margin-top: 0.1rem;
  font-size: 14px;
  font-weight: 600;
}

.block-Trx {
  font-size: 14px;
  font-weight: 600;
}



.blockhash {
  color: rgb(118, 13, 13);
  font-weight: 600;
}

.blockhash1 {
    display: none;
    color: rgb(118, 13, 13);
    margin-left: 70px;
    margin-top: -22px;
}
.blockHash-Validator {
  font-weight: 600;
}

.block-blockNumber{
  color:rgb(118, 13, 13);
  font-weight: 600;

}


.block-cards {
    text-align: left;
    margin-top: 2rem;
}

.headingBlocks{
  color:#333;
  font-weight: 700;
  font-size: 28px;
}

.transaction-container{
  padding: 20px 3%;
}

.headingTransactions{
  margin-top: 12px;
  margin-bottom: 10px;
  color:#333;
  font-weight: 700;
  text-align: center;
    font-size: 28px;
}
  

.transaction-body{
  text-align: left;
}
  .Alltransaction-tabs {
    display: flex;
    margin-top: 20px;
    margin-left: 80px;
    color: #2C5282;
  }
  
  /* list */
    .transactionlogo{
    width: 15px;
    text-align: left;
    margin-right: 5px;
  } 
  .transaction-body {
    padding: 20px;
  }
  
  .transaction-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
    border-bottom: 1px solid #ddd; /* Bottom border only */

  }
  
  .transaction-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
  }
  
  .transaction-info {
    grid-column: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .transaction-address {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: rgb(118, 13, 13);
  }
  
  .transaction-value-fee {
    grid-column: 3;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .transaction-details {
    grid-column: 4 / span 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .transaction-hash {
    display: flex;
    align-items: center;
    color: rgb(118, 13, 13);
    font-weight: 600;
  }
  
  .transactionlogo {
    margin-right: 10px;
  }
  

  
  .transaction-time {
    grid-column: 4 / span 2;
    text-align: right;
  }
  
  .alltransactions {
    text-align: center;
    cursor: pointer;
    color: rgb(109, 30, 30);
    font-weight: bold;
    font-size: 21px;
  }
  .transaction-status{
    color: rgb(173, 20, 20);
    font-weight: bold;
  
  }
  @media (max-width: 768px) {
    .block-cards {
      text-align: left;
      margin-left: 1rem;
      margin-top: 2rem;
  }

    .Alltransaction-detail{
      margin-left: 50px;
      display: flex;
      text-align: left;
      }


.Topaccounts-time{
  text-align: right;
  margin-left: 20px;
  margin-top: -24px;
}

.alltransactions{
  text-align: center;
 margin-top: 20px;
 
}
.buttenallblock{
  text-align: center;
  margin-right: 60px;
}



.from-icon{
  margin-left: 135px;

}
  .to-icon{
    margin-right: 20px;
    font-size: 18px;
    margin-top: -30px;
  }
  .transaction-item {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .transaction-info,
  .transaction-address,
  .transaction-value-fee,
  .transaction-details {
    grid-column: 1 / -1; /* Make each section span the full width */
  }

  .transaction-address {
    display: block;
  }

  .transaction-value-fee {
    display: block;
  }

  .transaction-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }

  .transactionblock-number {
    margin-right: 0;
  }

  .transaction-time {
    text-align: left;
  }

  }
  