/* Styles/Footer.css */

.footer {
    background-color: rgb(40, 37, 37);
    color: white;
    padding: 20px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .container {
    display: flex;
    justify-content: space-between; /* Space between the sections */
    flex-wrap: wrap; /* Allow wrapping on small screens */
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    padding: 10px;
    min-width: 200px; /* Minimum width for each section */
    box-sizing: border-box;
    text-align: left; /* Align text to the left */
  }
  
  .footer-section.footerdis1 {
    text-align: left; /* Align text to the center for footerdis1 */
  }
  
  .footer-section.footerdis2 {
    text-align: left; /* Align text to the right for footerdis2 */
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section li {
    margin: 5px 0;
  }
  
  .footer p {
    margin-top: 0;
  }
  
  @media (max-width: 600px) {
    .container {
      flex-direction: column;
    }
    .footer-section {
      margin-bottom: 20px;
    }
  }
  