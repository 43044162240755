.Alltransaction-detail-container {
  padding-left: 3%;
  padding-right: 3%;
}
.Alltransaction-heading {
  font-size: 30px;
  font-weight: 500;
  text-align: left;
}

.Alltransaction-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.Alltransaction-grid-item {
  background-color: #f0d0d0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-top: 0;
}

.Alltransaction-grid-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Alltransaction-grid-title {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
  color: #303030;
  margin-left: 10px;
  margin-top: 10px;
  font-weight: 600;
}

.Alltransaction-grid-value-container {
  position: relative;
  height: 15px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.Alltransaction-grid-value {
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -10px;
  color: #972929;
}

.Alltransaction-grid-subtitle {
  font-size: 12px;
  color: #6a2121;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;
}
.paginations {
  display: flex;
  align-items: right;
  justify-content: right;
}

.alltransaction-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0px;
  border-bottom: 1px solid #ddd; /* Bottom border only */
   padding: 0 3%;
}

.alltransaction-item {
  display: flex;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 1px;
  margin-top: 8px;
}

.alltransaction-from{
  cursor: pointer;
  display: flex;
  margin-left: 0.5rem;
  font-weight: 600;
}
.alltransaction-to{
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  font-weight: 600;
}

.transaction-value{
  font-weight: 600;
}

.transaction-fee{
  font-weight: 600;
}

.transactionblock-number{
  font-weight: 600;
}

.transaction-time1{
  font-weight: 600;
}


@media (max-width: 768px) {

  .Alltransaction-grid {
    grid-template-columns: 1fr;
    margin-left: 5px;
    margin-right: 5px;
    gap: 15px;
  }
  .Alltransaction-grid1 {
    grid-template-columns: 1fr;
    margin-left: 15px;
    margin-right: 15px;
    gap: 15px;
  }

  .Alltransaction-grid-item {
    background-color: #f0d0d0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    padding-top: 1px;
    padding-bottom: 8px;
  }
  .alltransaction-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .alltransaction-item {
    text-align: left;
    grid-template-columns: 1fr;
  }

  .alltransaction-item .transaction-status,
  .alltransaction-item .transaction-hash {
    grid-column: span 2; /* Ensures these items take up full row in mobile view */
  }

  .alltransaction-grid .alltransaction-item:nth-child(n+3) {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

}
