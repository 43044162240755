.dashboard-heading {
    font-size: 28px;
    font-weight: bold;
    text-align: left;
   
}

.dashboard-container {
    padding: 0 3.5%;
}

.dashboard-card {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 3% 4% 4%;
}

.dashboard-text {
    font-size: 21px;
    font-weight: 600;
    text-align: left;
    position: relative;
    top: 4px;

}

.dashboard-address-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 5px;
}

.dashboard-address {
    font-size: 17px;
    word-break: break-word;
    text-align: left;
    cursor: pointer;
    transition: transform 0.3s ease, border 0.3s ease;
    color: #333;
}

.dashboard-address1 {
    display: none;
}

.dashboard-address:hover {
    transform: scale(1.1);
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 2px;
}

.dashboard-balance {
    font-size: 24px;
    word-break: break-word;
    text-align: left;

}

.copy-button {
    margin-left: 20px;
    background: #333;
    border: none;
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
   
}

.copy-button:hover {
    background: #333;
}

.receive-button {
    background-color: #0f9844;
    color: #ffffff;
    margin-left: 20px;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: -30px;
}

.send-button {
    background: #ea3138;
    
    color: #ffffff;
    margin-left: 20px;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: -30px;
}

.alert {
    width: 80%;
    margin: 20px auto;
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
}

.alert-info {
    background-color: #e7f3fe;
    border-color: #b3d7ff;
    color: #31708f;
}

.dashboard-card-usdt {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    flex: 1 0 42%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    line-height: 3;
}

.dashboard-card-inr {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    flex: 1 0 42%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    line-height: 3;
    gap: 15px;
    overflow: hidden;
}

.value-card {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.dashboard-usdt-text {
    margin-top: 0;
    text-align: left;
    color: #333;
    font-weight: 400;
    line-height: 4;
    display: flex;
    align-items: center;
    font-size: 25px;
}

.dashboard-usdt-balance {
    font-size: 35px;
    margin-top: 0px;
    text-align: left;
    color: #ea3138;
    display: flex;
    align-items: center;
   
}

.dashboard-usdt-text1 {
    color: #718096;
    font-size: 14px;
    font-weight: bold;
    margin-top: -26px;
    text-align: left;
    margin-left: 160px;
}
.rubybalancelogo {
    text-align: left;
    width: 20px;
    margin-right: 3px;
    margin-top: 5px;
    margin-left: 20px;
    position: relative;
    top: -3px;
}
.usdtlogo {
    margin-right: 4px;
    position: relative;
    text-align: left;
    width: 18px;
    top:-1px;
}

.inrlogo {
    text-align: left;
    width: 22px;
    margin-right: 4px;
}

.dashbord-tabs {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 0 2.6%;
}

.dashbord-button {
    border: 20px;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px;
    background-color: transparent;
    border: 5px;
    outline: none;
    font-weight: 600;
    font-size: 20px;
}

.dashbord-button.active {
    background-color: #ea3138;
    color: white;
    border: 5px;
    border-radius: 8px;
}

.dashbord-button:hover {
    color: #007bff;
}


.recent-transactionsheading {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    margin-left: 120px;
    width: 82%;
    height: 40px;
    position: relative;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -10px;
    font-size: 14px;
}

.qr-code-container {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 0px auto;
    width: 50%;
    height: 250px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    position: relative;
    z-index: 2;
}



.Alltransfer-Type-text {
    display: none;
}



.dashboard-Transactions{
    margin-left: 50px;
    margin-right: 50px;
}
.dashbordtransction-Method{
 display: none;
}

.dashbordtransction-hash{
    color: #2C5282;
    margin-right: 30px;
    margin-top: -20px;
  }

  .transfer-type{
    color: #2C5282;
    background-color: #bdd1e9;
    border-radius: 6px;
    padding-bottom: 1px;
    padding-left: 5px;
    padding-bottom: 1px;
    padding-right: 5px;
    margin-top: 20px;
    display: inline-block; 
  }

  .dashboard-contractaddress {
    text-align: right;
    display: flex;
    margin-right: 100px;
    color: #235f9b;
}
.dashboard-contractbalance{
    margin-right: 700px;
    display: flex;
    color: #235f9b;
    text-align: right;
   
}
.dashboard-token-name{
    display: flex;
    color: #235f9b;  
    margin-left: 100px;
    margin-top: -25px;
}
.dashbord-token-detail {
    display: flex;
    margin-left: 100px;
    margin-right: 100px;
  
}

.dashboard-token-transfer{
    display: flex;
    color: #235f9b;  
    margin-left: 250px;
    margin-top: -25px;
}
.dashboard-send{
    color: #9b2323; 
    margin-right: 20px; 
    cursor: pointer;
    background-color: #d29686;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;

}
.dashboard-receive{
    color: #228313; 
    cursor: pointer;
    background-color: #86bc8f;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}
.dashboard-card1{
    display: flex;
    align-items: center;
}
.buttonDash{
    width: auto;
    position: relative;
    top:4px
}


@media (max-width: 768px) {
    .dashboard-heading {
        margin-left: 20px;
        font-size: 24px;
    }

    .dashboard-card {
        width: 90%;
        height: 200px;
        padding: 15px;
        margin: 10px auto;
    }

    .dashboard-text {
        margin-left: 10px;
        font-size: 20px;
    }

    .dashboard-address-container {
        margin-left: 10px;
        flex-direction: row;
        align-items: flex-start;
        margin-right: 10px;
    }

    .dashboard-address {
        display: none;
    }

    .dashboard-address1 {
        color: #0f579a;

        display: flex;
        margin-left: 0px;
        margin-top: 10px;
    }

    .dashboard-balance {
        display: flex;
        width: 100%;
    }

    .copy-button {
        display: flex;
     
    }

    .receive-button {
        display: flex;
        margin-left: 0px;
        margin-top:0;
    }
    .dashbord-button:focus {
        background-color: #ea3138;
        color: white;
    }
    .dashbord-button.active {
        background-color: #ea3138;
        color: white;
    }
    .buttonDash {
        width: 100%;
        position: relative;
        top: 0;
        display: flex;
        justify-content: end;
    }
    .send-button {
        display: flex;
        margin-left: 0;
        margin-top:0;
    }

    .alert {
        width: 90%;
    }

    .value-card {
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2%;
        flex-direction: column;
    }

    .dashboard-card-usdt,
    .dashboard-card-inr {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        justify-content: center;
    }

    .dashboard-card-inr{
        line-height: normal;
    }

    .dashboard-usdt-text,
    .dashboard-usdt-balance,
    .dashboard-usdt-text1 {
        margin-left: 20px;
    }


    .dashbord-tabs {
        margin-left: 0;
        padding: 0;
    }
    .dashbord-button {
        font-size: 14px;
        padding: 8px 5px;
    }
    .dashbord-button.active {
        color: white;
    }

  

    .recent-transactionsheading {
        display: none;
    }


    .dashbordtransction-Method{
        display: flex;
        text-align: right;
        margin-top: 34px;
        margin-left: 300px;
        cursor: pointer;
        position: relative;
    }
    .dashbordtransctionTansfercss {
        background-color: #efefef;
        color: #0f9844;
        border-radius: 17px;
        display: inline-block;
        margin-top: -50px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 12px;
        font-size: 14px;
        position: relative;
    }
    .qr-code-container {
        background-color: #f8f9fa;
        border: 1px solid #ddd;
        border-radius: 8px;
        margin-left: 40px;
        width: 80%;
        height: 300px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;
        position: relative;
        z-index: 2;
    }
    .usersendrecived-transfer{
        text-align: right;
        margin-top: -20px;
        border-radius: 5px;
        padding-bottom: 1px;
        padding-left: 5px;
        padding-bottom: 0px;
        padding-right: 5px;
        position: relative;

    }
    .usersendrecived-transfer1{
        color: green;
        background-color: rgb(194, 225, 194);
        border-radius: 5px;
        padding-bottom: 1px;
        padding-left: 5px;
        padding-bottom: 0px;
        padding-right: 5px;
        display: inline-block; 
        position: relative;       

        
    }
.dashbord-token-detail{
        display: none;
    }
    .dashboard-send{
        color: #9b2323; 
        margin-right: 20px; 
        cursor: pointer;
        background-color: #d29686;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
    
    }
    .dashboard-token-transfer{
        margin-top: -30px;
    }
   
}


@media (max-width: 500px) {

    .rubybalancelogo{
    width: 33px;
    margin-right: 3px;
    margin-top: 5px;
    margin-left: 0;
    position: relative;
    top: -3px;
    }

    .dashboard-card1 {
        display: flex;
        align-items: center;
        flex-direction: column;
        
    }
    .dashboard-text {
        width: 100%;
    }
    .dashboard-address1 {
        margin-top: 0;
        width: 100%;
    }
    .copy-button {
        display: flex;
     
    }
    .dashboard-address-container {
        align-items: center;
        position: relative;
        flex-direction: row;
        width: 100%;
    }
.buttonDash{
    width: 100%;
    display: flex;
        justify-content: flex-start;
}
    }