.search-txn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 5%;
    margin-right: 5%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    border: 2px solid #ccc;
}

.search-txn span:nth-child(1){
    position: absolute;
    background-color: #ea3138;
    width: 50px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}


.search-txn span:nth-child(1) img{
    width: 20px;
}


.search-input {
    padding: 10px;
    font-size: 16px;
    border:none;
    border-radius: 4px;
    margin-right: 10px;
    flex: 1;
}

.search-input:focus{
    outline: none;
    
}


.search-query{
    background-color: rgb(255, 255, 255);
    color: black;
    border-radius: 10px;
    width: 87%;
    height: 80px;
    margin-left: 80px;
    border: 1px solid #ccc;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}
.search-data-mobile{
display: none;
}
.Invalidinput{
    margin-bottom: 500px;
}

@media (max-width: 768px) {

    .search-txn {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 30px;
        margin-left: 14px;
        margin-right: 10px;
        border-radius: 5px;
    }
    .search-query{
        background-color: rgb(255, 255, 255);
        color: black;
        border-radius: 10px;
        width: 87%;
        height: 80px;
        margin-left: 0px;
        border: 1px solid #ccc;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .search-data-mobile{
        display: flex;
    }
    .search-data{
        display: none;
    }
    
}